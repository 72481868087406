<template>
    <div>
        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="save" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="exampleModalLabel"> <img src="../../../public/sensor.png"> {{ form.id && isDetail === true ? "Detail Vending Type" : form.id ? "Edit Vending Type" : "Add Vending Type"}}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <label for="title" class="col-form-label">Vending Type<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" required id="title" placeholder="Ex. Vending A"
                                        v-model="form.type_name" :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="description" class="col-form-label">List of sensors</label>
                                    <div v-for="(sensor, idx) in sensorsSelected" :key="idx">
                                        <!-- <p>{{ sensor.data }}</p> -->
                                        <select2  v-model="sensorsSelected[idx].id" :config="sensorConfig" required :disabled="isDetail" class="form-control">
                                            <option :value="sensor.id" selected v-if="sensor.title">{{ sensor.title }}</option>
                                        </select2>
                                    </div>
                                    <div v-if="!isDetail" style="margin-top: 20px;">
                                        <button type="button" @click="addSensor" class="btn btn-link"><span class="fas fa-plus" style="margin-right: 10px;"> </span>Add Sensor</button>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetVendingType">Close</button>
                            <button type="submit" class="btn btn-primary" :disabled="loading || isDetail">
                                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                {{ loading ? 'Saving ...' : 'Save' }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="deleteData" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="deleteModalLabel"> <img src="../../../public/sensor.png"> Delete Sensor</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <span>Are you sure you want to delete?</span>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Deleting...' : 'Delete' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark capitalize">
                            Vending Type
                        </h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right ">
                            <!-- <li>
                                <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                            </li> -->
                            <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"
                                    @click="showModal"><span class="fas fa-plus" style="margin-right: 10px;"> </span> Add Vending Type</button></li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <data-tables-master-data :config="config" class="container-fluid" id="sampleTable"></data-tables-master-data>

    </div>
</template>


<script>
import DataTablesMasterData from '@components/DataTablesMasterData.vue'
// import select2 from 'vue-select2'

const {console} = window
export default {
    components: {
        DataTablesMasterData,
        // select2
    },

    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            error: undefined,
            loading: false,
            text_validation: null,
            maxSize: 1024,
            form: {
                id: null,
                type_name: "",
                sensors: [],
            },
            sensorsSelected: [{
                id: null
            }],
            sensorConfig: {},
            disabled: false,
            isDetail : false,
            options: [],
            selectedOption: [],
            config: {
                columns: [{
                    title: 'ID Vending',
                    data: 'id'
                }, {
                    title: 'Vending Type',
                    data: 'type_name'
                }, {
                    title: 'List of sensors',
                    data: 'sensors',
                    render: (dt) => {
                        let titleSensor = dt.map((e) => e.title).join(", ")
                        return titleSensor
                    },
                    searchable: false
                }, {
                    title: 'Action',
                    data: 'id',
                    render: (val) => {
                        return `
                        <div> 
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                class="editor-detail" 
                                data-action="detail" 
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-eye"></i>
                            </a>
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                class="editor-edit" 
                                data-action="edit"
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-edit"></i>
                            </a>
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                data-action="delete" 
                                class="editor-delete" 
                                style="color:#9E9E9E;"
                            >
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>`
                    }
                }],
                "drawCallback": ( settings ) => {
                        //for click detail
                        $(".editor-detail").on( 'click', (e) => {
                            this.getDetail($(e.target).closest('a').data('id'))
                        });

                        //for click edit
                        $(".editor-edit").on( 'click', (e) => {
                            this.getEdit($(e.target).closest('a').data('id'))
                        });
                        $(".editor-delete").on( 'click', (e) => {
                            this.showModalDelete($(e.target).closest('a').data('id'))
                        });

                },
                url: this.Api.base + '/vendingType',
                order: [[0, "desc"]],
                filter: []
            }
        }
    },
    mounted() {
        this.getSensor()
        this.initData()
    },
    props: {
        value: Object,
    },
    computed: {
        console: () => console,
    },
    methods: {
        getDetail(id) {
            if(id) {
                this.Api.get(`/vendingType/${id}`).then(res =>{
                    if(res.data) {
                        $('#exampleModal').modal('show');
                        this.form.id = res.data.id;
                        this.form.type_name= res.data.type_name;
                        this.sensorsSelected = JSON.parse(JSON.stringify(res.data.sensors));
                        this.isDetail = true
                    }
                })
                .catch((e) =>{
                    console.log(e)
                })  
            }
        },
        getEdit(id) {
            if(id) {
                this.Api.get(`/vendingType/${id}`).then(res =>{
                    if(res.data) {
                        $('#exampleModal').modal('show');
                        this.form.id = res.data.id;
                        this.form.type_name= res.data.type_name;
                        this.sensorsSelected = JSON.parse(JSON.stringify(res.data.sensors));
                        this.isDetail = false
                    }
                })
                .catch((e) =>{
                    console.log(e)
                })  
            }
        },
        showModalDelete(id) {
            $('#deleteModal').modal('show');
            this.form.id = id
        },
        deleteData() { 
            this.loading = true
            if(this.form.id) {
                this.Api.delete(`/vendingType/${this.form.id}`).then(
                    this.loading = false,
                    this.refresh(),
                    $('#deleteModal').modal('hide')
                )
            }
        },
        addSensor() {
            this.sensorsSelected.push({id: null})
        },
        resetVendingType() {
            this.form = {
                type_name : "",
                sensors : [{
                    id: null
                }],
            }
            this.sensorsSelected = [{
                id: null
            }]
            this.isDetail = false
        },
        showModal() {
            this.resetVendingType()
            this.form.id = null;
            this.type_name = null;
            this.sensors = null;
            $("#exampleModal").modal("show");
        },
        defaultBtnImage() {
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();
        },
        initData() {
            this.sensorConfig = {
                ajax: {
                    url: env.api.base + '/sensor',
                    data: params => {
                        var filters = [
                            ["title", "like", params.term]
                        ]
                        var query = {
                            // sort: "title",
                            filters: params.term ? JSON.stringify(filters) : "",
                            page: params.page ? params.page : 1
                        }
                        return query
                    },
                    processResults: (data, params) => {
                        data.content.forEach(item => {
                            item.text = item.title

                        })
                        return {
                            results: data.content,
                            pagination: {
                                more: data.last == false
                            }
                        }
                    }
                }
            }
        },
        getSensor() {
                this.Api.get(`/sensor/`).then(res =>{
                   this.options = res.data.content
                })
                .catch((e) =>{
                    console.log(e, "error")
                })  
        },
        save() {
            this.loading = true
            this.form.sensors = this.sensorsSelected
            this.Api.post('/vendingType', this.form).then(res => {
                this.loading = false
                this.initData();
                this.refresh();
                this.resetVendingType()
                $('#exampleModal').modal('hide');
            }).catch((e) => {
                this.loading = false
                this.error = e.response.data.message
                this.text_validation = "Submit Failed";  
            })
        },
        refresh() {
            this.config = {
                url: this.Api.base + '/vendingType'
            }
        },
        
    },
}
</script>
